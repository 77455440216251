import React, { useEffect } from 'react';
import { FaRegLightbulb, FaRegChartBar, FaRegClock, FaLock, FaUsers, FaMoneyBill, FaUserTie, FaHeadset, } from 'react-icons/fa';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from '../Footer/footer';
import { useNavigate } from 'react-router-dom';
import bannerImage from '../../Assets/banner-image2.png'
import bannerImage2 from '../../Assets/banner-image3.png'
import bannerImage3 from '../../Assets/banner-image4.png'
import Slider from 'react-slick';
import { getToken } from '../../StorageMechanism/storageMechanism'

import {
  HomeContainer,
  Section,
  FeatureIcon,
  FeatureTitle,
  FeatureDescription,
  MainSection,
  H1,
  Description2,
  BannerSectionTextContainer,
  BannerSectionImageContainer,
  CTAButton,
  Heading,
  ReviewsContainer,
  ReviewCard,
  Span,
  Content,
  ServiceCardContainer,
  ServiceCard,
  MeanyMoreContainer,
  ManyMoreButton,
} from './styledComponents';
import PositiveReviewsPopup from '../ReviewPopup/reviewPopup';
import ReferralSection from './referralSection';
import BenefitsWithUniprofin from './benifits';
import HowWeWork from './howwework';

const Landingpage = () => {

  const reviews = [
    { name: 'Madhavi Shetty', review: 'UniProFin has made managing my finances a breeze! The intuitive features and detailed insights have greatly simplified my financial planning.' },
    { name: 'Naveen Tivaari', review: 'I love the user-friendly interface of UniProFin. It\'s easy to navigate, and the visuals help me understand my financial data better.' },
    { name: 'Varun Sangvan', review: 'The customer support at UniProFin is top-notch. They are responsive, knowledgeable, and go above and beyond to assist with any queries or issues.' },
    { name: 'Riya Patil', review: 'UniProFin helped me achieve my financial goals. The goal tracking feature motivated me to save more, and I successfully reached my savings target.' },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    const token = getToken()
    if (token) {
      navigate('/user/dashboard')
    }
    const timeoutId = setTimeout(() => {
      <PositiveReviewsPopup />
    }, 500)
    return () => clearTimeout(timeoutId)
  })

  // Slider images data
  const sliderImages = [
    {
      src: bannerImage,
      alt: 'Image1',
    },
    {
      src: bannerImage2,
      alt: 'Image2',
    },
    {
      src: bannerImage3,
      alt: 'Image3',
    }
  ];

  const whyChooseUsFeatures = [
    {
      id: 1,
      title: 'Expert Support',
      description:
        'Get expert support and guidance from our dedicated team to address your concerns. Our knowledgeable professionals are here to assist you at every step of the way.',
      icon: <FaRegLightbulb />,
    },
    {
      id: 2,
      title: 'Comprehensive Reporting',
      description:
        'Access detailed tax reports and analysis to make informed financial decisions. Our comprehensive reporting tools provide you with a clear understanding of your financial data.',
      icon: <FaRegChartBar />,
    },
    {
      id: 3,
      title: 'Time-saving Solutions',
      description:
        'Save time with our efficient solutions. We understand the value of your time, and our tools are designed to streamline your financial processes, giving you more time for what matters.',
      icon: <FaRegClock />,
    },
    {
      id: 4,
      title: 'Secure Transactions',
      description:
        'Ensure the security of your transactions. We prioritize the safety of your financial data and implement robust security measures to protect your information from unauthorized access.',
      icon: <FaLock />,
    },
    {
      id: 5,
      title: 'Collaborative Environment',
      description:
        'Experience a collaborative environment. Our platform allows seamless collaboration between different users, making it easy for teams to work together on financial tasks.',
      icon: <FaUsers />,
    },
    {
      id: 7,
      title: 'Efficient Tax Planning',
      description:
        'Optimize your tax liabilities with our efficient tax planning services. We tailor our solutions to your unique financial situation, maximizing your benefits.',
      icon: <FaMoneyBill />,
    },
    {
      id: 8,
      title: 'Personalized Consultations',
      description:
        'Receive personalized consultations from our experts. We take the time to understand your specific needs and provide tailored advice to help you achieve your financial goals.',
      icon: <FaUserTie />,
    },
    {
      id: 10,
      title: 'Dedicated Client Support',
      description:
        'Enjoy dedicated support from our client service team. We are committed to addressing your inquiries and ensuring a smooth experience with our tax management services.',
      icon: <FaHeadset />,
    },
  ];

  const ourServicesFeatures = [
    {
      id: 1,
      title: 'Personal Tax Planning',
      description:
        'We offer personalized tax planning services to optimize your tax liabilities and maximize your financial benefits.',
    },
    {
      id: 2,
      title: 'Business Tax Advisory',
      description:
        'Our experts provide strategic tax advisory services to businesses, helping them navigate complex tax structures and regulations.',
    },
    {
      id: 3,
      title: 'Tax Compliance Services',
      description:
        'Ensure compliance with the latest tax regulations with our comprehensive tax compliance services. We keep you informed and up-to-date.',
    },
    {
      id: 4,
      title: 'Estate Tax Planning',
      description:
        'Plan for estate and inheritance taxes with our specialized services. We help you develop strategies to minimize tax liabilities in estate planning.',
    },
    {
      id: 5,
      title: 'Nonprofit Tax Services',
      description:
        'Access tax services tailored for nonprofit organizations. We understand the unique tax considerations for nonprofits and provide specialized support.',
    },
    {
      id: 6,
      title: 'Financial Analysis',
      description:
        'Benefit from detailed financial analysis services. Our team provides insights into your financial data to support informed decision-making.',
    },
    {
      id: 7,
      title: 'Strategic Business Planning',
      description:
        'Develop strategic business plans with our expert guidance. We assist businesses in creating plans that align with their financial goals and objectives.',
    },
    {
      id: 8,
      title: 'IRS Issue Resolution',
      description:
        'Get support and representation during IRS audits and appeals. We stand by you to address challenges related to the IRS.',
    },
    {
      id: 9,
      title: 'Effortless Taxpayer Registration',
      description:
        'Experience effortless taxpayer registration with our user-friendly processes. We make it simple for individuals to register and access our tax services.',
    },
    {
      id: 10,
      title: 'Integrated Electronic Filing',
      description:
        'Streamline your tax filing with our integrated electronic filing services. We leverage technology to make the filing process efficient and convenient for our clients.',
    },
  ];


  const sliderSettings = {
    dots: false,
    infinite: true,
    prevArrow: null,
    nextArrow: null,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,

  };

  return (
    <HomeContainer>
      {/* <PositiveReviewsPopup /> */}
      <MainSection id="your-cta-section">
        <BannerSectionTextContainer>
          <H1>Welcome to UniProFin</H1>
          <Description2>
            Unlock the power of financial freedom with UniProFin. Our expert services guide you towards informed decisions for a secure financial future.
          </Description2>
          <CTAButton onClick={() => navigate('/accounts/login')}>Get Started</CTAButton>
        </BannerSectionTextContainer>
        <BannerSectionImageContainer>
          <Slider {...sliderSettings}>
            {sliderImages.map((image, index) => (
              <div key={index}>
                <img
                  src={image.src}
                  alt={image.alt}
                  className="slider-image"
                />
              </div>
            ))}
          </Slider>
        </BannerSectionImageContainer>
      </MainSection>

      <HowWeWork />
      <Section id="whyChooseUs" >
        <Heading>Benefits of Choosing Us</Heading>
        <ServiceCardContainer>
          {whyChooseUsFeatures.map((feature) => (
            <ServiceCard key={feature.id}>
              <FeatureIcon>{feature.icon}</FeatureIcon>
              <FeatureTitle>{feature.title}</FeatureTitle>
              <FeatureDescription>{feature.description}</FeatureDescription>
            </ServiceCard>
          ))}
        </ServiceCardContainer>
      </Section>

      <Section id="ourServices" >
        <Heading>Our Services</Heading>
        <ServiceCardContainer>
          {ourServicesFeatures.map((feature) => (
            <ServiceCard key={feature.id}>
              <FeatureTitle>{feature.title}</FeatureTitle>
              <FeatureDescription>{feature.description}</FeatureDescription>
            </ServiceCard>
          ))}
        </ServiceCardContainer>
      </Section>

      <Section className='mt-5 mb-5'>
        <Heading>What people say</Heading>
        <ReviewsContainer>
          {reviews.map((review, index) => (
            <ReviewCard key={index} >
              <Content><span>" </span>{review.review} <span>" </span></Content>
              <Span>
                -- {review.name}
              </Span>
            </ReviewCard>
          ))}
        </ReviewsContainer>
        <ReferralSection />
        <MeanyMoreContainer>
          <ManyMoreButton>Many more...</ManyMoreButton>
        </MeanyMoreContainer>
      </Section>
      <BenefitsWithUniprofin />
      <Footer />
    </HomeContainer>
  );
};

export default Landingpage;




import styled from "styled-components";
// Styled components
export const HomeContainer = styled.div`
  position: relative;
  background-color:var(--main-background);
  
`;

export const ImageSlide = styled.img`
  width: 100%;
  height: auto;
   
`;

export const CarouselCaption = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
`;

export const Section = styled.section`
   text-align:center;
   background-color:var(--main-background);
   ${'' /* justify-content:center; */}
   padding:30px 80px;
   
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

export const SectionTitle = styled.h2`
  font-family: 'Bree Serif', serif;
  color: #333;
  font-size: 2.5em;
  margin-bottom: 30px;
  @media screen and (max-width:768px){
    font-size:24px;
  }
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width:100%;
  justify-content: space-around;
`;

export const FeatureItem = styled.div`
  width: 100%;
  max-width: 550px;
  padding: 20px;
  margin-bottom: 40px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media screen and (min-width: 768px) {
    width: 45%;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
  }
`;

export const FeatureIcon = styled.div`
  font-size: 4em;
  color: var(--accent-background);
  margin-bottom: 20px;
   @media screen and (max-width:768px){
    font-size: 3em;
  }
`;

export const FeatureTitle = styled.h3`
  font-size: 2px;
  margin-bottom: 10px;
  color: #333;

  @media screen and (max-width:768px){
    font-size:12px;
  }
`;

export const FeatureDescription = styled.p`
  font-size: 1.2em;
  color: #555;
   @media screen and (max-width:768px){
    font-size:0.8rem;
  }
`;

export const MainSection = styled.section`
  padding: 20px;
  width: 100%;
  min-height: 100vh;
  color: var(--background-with);
  background-color: var(--accent-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding:50px;
  }
`;

export const H1 = styled.h1`
  color: white;
  font-size: 5.5vw;
  margin-bottom: 30px;
  position: relative;
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    bottom: -20px; 
    left: 32%;
    transform: translateX(-50%);
    width: 200px;
    height: 10px;
    background-color: #ffa500; 
    border-radius: 50%;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.3); 
  }

  @media (min-width: 768px) {
    font-size: 60px;

    &::after {
      width: 500px;
      height: 20px;
      left: 50%;
      border-radius: 50%;
    }
  }
`;



export const Description2 = styled.p`
  color: white;
  font-size: 3vw;

  @media (min-width: 768px) {
    font-size: 30px;
  }
`;

export const BannerSectionTextContainer = styled.div`
  padding: 10px;
  width: 100%;
  order:2;

  @media (min-width: 768px) {
    width: 50vw;
    order:1;
  }
`;

export const BannerSectionImageContainer = styled.div`
  width: 100%;
  text-align: center;
  order:1;
  padding:20px;

  @media (min-width: 768px) {
    width: 50vw;
    order:2;
  }
`;

export const CTAButton = styled.a`
  display: inline-block;
  background-color: #ffa500;
  color: #ffffff;
  padding: 15px 30px;
  font-size: 3vw;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7f00;
  }

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;


export const ReviewsContainer = styled.div`
  display: flex;
  overflow: auto;
`;

export const ReviewCard = styled.div`
  min-width: 300px;
  display:flex;
  flex-direction:column;
  flex:1;
  margin: 10px;
  padding: 20px;
  text-align: left;
  font-style:italic;
  color:white;
  background-color: var(--accent-background);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
`;

export const Content = styled.p`
  flex:1;
`

export const Span = styled.span`
    font-style: italic;
    font-size: 22px;
    color: #ff7f00;  
    text-align:end;
`


export const Heading = styled.h1`
    font-style:italic;
    font-weight:bold;
    color:var(--accent-background);
    text-align:center;
    margin-bottom:20px;
    @media screen and (max-width:768px){
        font-size:20px;
    }
`

export const CloseButton = styled.button`
    border:none;
    color:white;
    font-size:50px;
    height:60px;
    width:60px;
    background:transparent;
    @media screen and (max-width:768px){
        font-size:30px;
    }
`


export const ServiceCardContainer = styled.div`
  padding:20px ;
  display: flex;
  flex-wrap: wrap;
  width:100%;
  justify-content: space-around;
  gap: 2rem;
  margin-top: 2em;
  @media screen and (max-width:768px){
    padding:0px;
  }
`;

export const ServiceCard = styled.div`
  flex: 1 1 300px;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 8px var(--border);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column; 
  justify-content:flex-start;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px var(--border);
  }

  h3 {
    color: var(--main);
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }

  p {
    color: #555;
    font-size: 1.2em;
    line-height: 1.6;
    flex: 1;
  }
`;

export const MeanyMoreContainer = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-end;
  padding:10px;
`

export const ManyMoreButton = styled.button`
  width:120px;
  height:40px;
  background-color:var(--accent-background);
  border:none;
  color:#ffa500;
  border-radius:50px;
`
import React from "react";
import styled from "styled-components";
import { FaCheckCircle } from "react-icons/fa";

const Section = styled.section`
  background: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 2rem auto;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 1.75rem;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 1.5rem;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  background: #f9f9f9;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    background: #e9f7ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const Icon = styled.div`
  color: #28a745;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span`
  margin-left: 1rem;
  font-size: 1.1rem;
  font-weight: bold;
  color: #343a40;
  background: linear-gradient(to right, #e0f7fa, #e9ffe9);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
`;

const BenefitsWithUniprofin = () => {
  const benefits = [
    "Extraordinary service at low cost.",
    "Round the clock expert support & assistance.",
    "100% data security.",
    "Early bird discounts and Referral bonus.",
    "W7 assistance & W4 assistance.",
    "IRS Representation and Resolving IRS Query’s.",
    "FBAR & FATCA Services.",
    "Individuals and Business Tax Planning.",
    "Extension filing & Amendment services.",
  ];

  return (
    <Section>
      <Title>Benefits with Uniprofin</Title>
      <List>
        {benefits.map((benefit, index) => (
          <ListItem key={index}>
            <Icon>
              <FaCheckCircle />
            </Icon>
            <Text>{benefit}</Text>
          </ListItem>
        ))}
      </List>
    </Section>
  );
};

export default BenefitsWithUniprofin;

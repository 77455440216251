import React from "react";
import styled from "styled-components";
import { Heading } from "./styledComponents";

const Section = styled.section`
  background: linear-gradient(135deg, #007bff, #ffc107);
  color: #fff;
  padding: 2.5rem;
  width: 100%;
  margin: 2rem auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #fff;
`;

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const StepCard = styled.div`
  background: #fff;
  color: #343a40;
  flex: 1;
  margin: 0.5rem;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  text-align: left;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
`;

const StepHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const StepNumber = styled.div`
  background: #ffc107;
  color: #007bff;
  font-size: 1.5rem;
  font-weight: bold;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 1rem;
`;

const StepTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
  color: #343a40;
`;

const StepText = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
`;

const HowWeWork = () => {
    const steps = [
        {
            title: "Schedule Interview",
            description:
                "Set up an appointment with our expert tax professionals at a time convenient for you. We'll guide you through every step.",
        },
        {
            title: "Upload Documents",
            description:
                "Securely upload your necessary documents, such as W-2 forms, 1099s, and other relevant files, through our portal.",
        },
        {
            title: "Check Free Tax Summary/payment",
            description:
                "Review a summarized draft of your tax returns, ensuring accuracy and completeness before moving forward.",
        },
        {
            title: "Review Return",
            description:
                "Go through the final tax return prepared by our experts, and make any necessary adjustments or clarifications.",
        },
        {
            title: "Confirm return for E-filing",
            description:
                "Once you're satisfied with the review, give us the go-ahead to file your tax return seamlessly and stress-free.",
        },
    ];

    return (
        <Section>
            <Heading>How We Work Here</Heading>
            <Title>Five easy steps to file your tax returns.</Title>
            <StepsWrapper>
                {steps.map((step, index) => (
                    <StepCard key={index}>
                        <StepHeader>
                            <StepNumber>{index + 1}</StepNumber>
                            <StepTitle>{step.title}</StepTitle>
                        </StepHeader>
                        <StepText>{step.description}</StepText>
                    </StepCard>
                ))}
            </StepsWrapper>
        </Section>
    );
};

export default HowWeWork;

// src/components/Footer.js
import React from 'react';
import { SiteFooter, FooterContainer, Row, Column, Heading, Paragraph, UnorderedList, ListItem, Address, CopyRightSection, MessageContainer, Message, ContactLink } from './styledComponents';
export const email = 'info@uniprofin.com'
export const support = 'support@uniprofin.com'
export const phone = '1-818-651-6456'
export const phone1 = '1-818-651-6456'
export const phone2 = '1-609-934-5255'
export const location = '35 Stonehouse Dr, Princeton, NJ 08540'

const Footer = () => {
    return (
        <SiteFooter>
            <FooterContainer>
                <Row>
                    <Column>
                        <Heading>About Us</Heading>
                        <Paragraph>
                            We are dedicated to simplifying tax management and financial services for individuals and businesses. Our goal is to make your financial life easier and more efficient.
                        </Paragraph>
                    </Column>
                    <Column>
                        <Heading>Quick Links</Heading>
                        <UnorderedList>
                            <ListItem><a href="/">Home</a></ListItem>
                            <ListItem><a href="/about">About</a></ListItem>
                            <ListItem><a href="/services">Services</a></ListItem>
                            <ListItem><a href="/contact">Contact</a></ListItem>
                        </UnorderedList>
                    </Column>
                    <Column>
                        <Heading>Head Office</Heading>
                        <Address>
                            <Paragraph><strong>Address:</strong> {location}</Paragraph>
                            <Paragraph><strong>Email:</strong> {email}</Paragraph>
                            <Paragraph><strong>Phone 1:</strong> {phone1}</Paragraph>
                            <Paragraph><strong>Phone 2:</strong> {phone2}</Paragraph>
                        </Address>
                    </Column>
                </Row>
                <CopyRightSection>
                    <Paragraph>&copy; 2020 UniProFin. All rights reserved.</Paragraph>
                    <Paragraph>For further assistance or if you have any questions, please don't hesitate to mail us <span style={{ color: 'yellow' }}>{support}</span>.</Paragraph>
                </CopyRightSection>
            </FooterContainer>
        </SiteFooter>
    );
};

export default Footer;



export const message = <MessageContainer className='shadow'>
    <Message> <strong className='text-start'>Note: </strong>
        Please feel free to contact us at{' '}
        <ContactLink href=''>{phone}</ContactLink> for any further assistance or
        send the documents or mail to <ContactLink >{support}</ContactLink>.
    </Message>
</MessageContainer>

